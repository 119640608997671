import { isNil } from "lodash";
import { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import { buildLink } from "../../../utils/link/buildLink";
import IconDescriptionGroup from "../../views/IconDescriptionGroup/IconDescriptionGroup";

type CMSIconDescriptionGroupProps = {
  data: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["icon_links"];
};

const CMSIconDescriptionGroup = ({ data }: CMSIconDescriptionGroupProps) => {
  const iconDescriptionData = useMemo(
    () =>
      data?.map(({ title, link, icon }) => ({
        description: title?.html,
        link: buildLink(link),
        icon: buildImageData(icon),
      })),
    [data]
  );

  if (isNil(iconDescriptionData)) {
    return null;
  }

  return <IconDescriptionGroup data={iconDescriptionData} />;
};

export default CMSIconDescriptionGroup;
