import { Grid } from "@mui/material";
import { columnSpacing } from "../../../../theme/Theme";
import { ImageData } from "../../../../utils/image/buildImageData";
import Image from "../../../elements/Image/Image";
import ContentA from "./ContentA";

type ContentAGroupProps = {
  data: ContentAGroupItem[];
};

type ContentAGroupItem = {
  title: string;
  body: string;
  imageData: ImageData;
};

const ContentAGroup = ({ data }: ContentAGroupProps) => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 4, sm: 5, md: 10 }}
      columnSpacing={columnSpacing}
    >
      {data.map(({ title, imageData, body }, index) => (
        <ContentA
          key={body}
          title={title}
          body={body}
          desktopImageAlignment={index % 2 === 0 ? "left" : "right"}
        >
          <Image image={imageData.image} alt={imageData.alt} />
        </ContentA>
      ))}
    </Grid>
  );
};

export default ContentAGroup;
