import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { columnSpacing } from "../../../../theme/Theme";
import InViewAnimationGrid from "../../../behaviors/InViewAnimation/InViewAnimationGrid";
import RichText from "../../../elements/RichText/RichText";

export type ContentAProps = {
  title?: string;
  body: string;
  children?: ReactNode;
  desktopImageAlignment?: "left" | "right";
};

const ContentA = ({
  title,
  body,
  children,
  desktopImageAlignment = "left",
}: ContentAProps) => {
  return (
    <InViewAnimationGrid
      xs={12}
      item
      container
      rowSpacing={{ xs: 2, sm: 3 }}
      columnSpacing={columnSpacing}
    >
      {!isNil(title) && (
        <Grid item xs={12}>
          <RichText text={title} />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        rowSpacing={{ xs: 2, sm: 3 }}
        columnSpacing={columnSpacing}
        sx={{
          flexDirection: {
            md: desktopImageAlignment === "left" ? "row" : "row-reverse",
          },
        }}
      >
        {!isNil(children) && (
          <Grid item xs={12} md={6}>
            {children}
          </Grid>
        )}
        {!isNil(body) && (
          <Grid item xs={12} md={6}>
            <RichText text={body} />
          </Grid>
        )}
      </Grid>
    </InViewAnimationGrid>
  );
};

export default ContentA;
