import { isNil } from "lodash";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import RichText from "../../elements/RichText/RichText";

export type CMSIntroTextProps = {
  data: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["intro_text"];
};

const CMSIntroText = ({ data }: CMSIntroTextProps) => {
  if (isNil(data?.html)) {
    return null;
  }

  return (
    <InViewAnimationGrid sx={{ width: "100%" }}>
      <RichText
        text={data?.html}
        textProps={{ textAlign: "center", width: "100%" }}
      />
    </InViewAnimationGrid>
  );
};

export default CMSIntroText;
