import { useMediaQuery, useTheme } from "@mui/material";
import { isNil } from "lodash";
import { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import ContentAGroup from "../../views/Content/ContentA/ContentAGroup";

type CMSContentAGroupProps = {
  data: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["content_a_group"];
};

const CMSContentAGroup = ({ data }: CMSContentAGroupProps) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const builtData = useMemo(
    () =>
      data?.map(({ image, mobile_image, title, body }) => ({
        title: title?.html,
        body: body?.html,
        imageData: isDesktop
          ? buildImageData(image)
          : buildImageData(mobile_image),
      })),
    [data, isDesktop]
  );

  if (isNil(builtData)) {
    return null;
  }

  return <ContentAGroup data={builtData} />;
};

export default CMSContentAGroup;
