import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { columnSpacing } from "../../../theme/Theme";
import { ImageData } from "../../../utils/image/buildImageData";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import Image from "../../elements/Image/Image";
import Link from "../../elements/Link/Link";
import RichText from "../../elements/RichText/RichText";

type IconDescriptionGroupProps = {
  data: IconDescriptionGroupItem[];
};

type IconDescriptionGroupItem = {
  icon: ImageData;
  description: string;
  link: string;
};

const IconDescriptionGroup = ({ data }: IconDescriptionGroupProps) => {
  return (
    <Grid container rowSpacing={{ xs: 3, sm: 4 }} columnSpacing={columnSpacing}>
      {data.map(({ description, link, icon }) => (
        <InViewAnimationGrid item xs={12} sm={4} key={description}>
          <Link
            to={link}
            sx={{
              textDecoration: "none",
              display: "block",
            }}
          >
            <Grid container rowSpacing={{ xs: 2, sm: 3 }}>
              <Grid item xs={12} container justifyContent="center">
                <Image alt={icon.alt} image={icon.image} />
              </Grid>
              {!isNil(description) && (
                <Grid item xs={12} container justifyContent="center">
                  <RichText
                    text={description}
                    textProps={{
                      textAlign: "center",
                      color: "primary.main",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Link>
        </InViewAnimationGrid>
      ))}
    </Grid>
  );
};

export default IconDescriptionGroup;
